<template>
  <div class="center">
    <div class="userinfos">
      <div>
        <div class="top userinfo">
          <img class="logo userTop" src="@/assets/icon/chatgpt.jpg" />
          <strong class="username">{{ $t("个人中心") }}</strong>
        </div>
        <el-menu :default-active="$route.name" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
          @select="handleSelect" background-color="#202123" text-color="#999" active-text-color="#fff">
          <el-menu-item index="userInfo">
            <i class="iconfont icon-userinfo1"></i>
            <span slot="title">{{ $t("我的信息") }}</span>
          </el-menu-item>
          <el-menu-item index="payForm">
            <i class="iconfont icon-recharge"></i>
            <span slot="title">{{ $t("充值记录") }}</span>
          </el-menu-item>
          <el-menu-item index="useForm">
            <i class="iconfont icon-shiyongjilu"></i>
            <span slot="title">{{ $t("使用记录") }}</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="footer">
        <div class="item" @click="" style="cursor: default">
          <i class="iconfont icon-yue"></i>
          <span>剩余用量： {{balance}}</span>
        </div>
        <div class="item" @click="logout()">
          <i class="iconfont icon-logout"></i>
          <span>{{ $t('退出登录') }}</span>
        </div>
      </div>
    </div>
    <div class="left">
      <!-- <div class="top" v-if="pagination">
        <el-tooltip
          class="item"
          effect="dark"
          content="back and answer"
          placement="bottom"
        >
          <i class="el-icon-back" @click="$router.push('/')"></i>
        </el-tooltip>
      </div> -->
      <!-- <i class="el-icon el-icon-arrow-left" @click="$router.push('/')"></i> -->
      <div class="recharge" v-if="pagination">
        <!-- <i class="iconfont icon-weibiaoti-_huaban el-icon" @click="$router.push('/ai-voice')"></i> -->
        <i></i>
        <el-button v-if="$route.name == 'payForm'" type="success" @click="$router.push('/recharge')">{{ $t('充值') }}</el-button>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
import { userInfo } from "../../config/api"

export default {
  name: "order",
  data() {
    return {
      useNum: "",
      username: "",
      balance: null,
      pagination: true
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getuserInfo()
    
  },
  filters: {
    descType(key) {
      if (key == 1) return "用户购买";
      if (key == 2) return "注册奖励";
      if (key == 3) return "系统奖励";
      if (key == 4) return "对话消耗";
    },
    actionType(key) {
      if (key == 1) return "增加";
      if (key == 0) return "消耗";
    }
  },
  computed: {
  },
  methods: {
    handleResize() {
      this.pagination = window.innerWidth > 768;
    },
    getuserInfo() {
      userInfo().then((res) => {
        this.balance = res.data.balance
      })
    },
    // 登出
    logout() {
      this.$router.push("login");
      localStorage.removeItem("userData");
      localStorage.removeItem("sessionId");
    },
    toOrder() {
      this.$router.push("order");
    },
    back() {
      this.$router.push({
        name: "home"
      });
    },
    handleOpen(key, keyPath) { },
    handleClose(key, keyPath) { },
    handleSelect(key, keyPath) {
      console.log(key)
      this.$router.push({
        name: key
      });
    }
  }
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-direction: row;
  height: 100vh;
  flex-direction: row;
  color: #ddd;
  overflow: hidden;

  .el-page-header__left {
    color: #303133;
  }

  // .left,.right {
  // width: 50%;
  // height: 100%;
  // padding: 5% 10% 0% 10%
  // }
  .userinfos {
    width: 260px;
    background-color: #202123;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    // padding 15px
    display: flex;
    justify-content: space-between;
    height: 100vh;
    flex-direction: column;

    .iconfont {
      font-size: 16px;
      margin: 0 4px 0 0;
    }

    .userinfo {
      width: 260px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      padding: 20px 0 0 20px;

      .userTop {
        width: 5rem;
        height: 5rem;
        line-height: 5rem;
        font-size: 2.5rem;
        text-align: center;
        background-color: #437eb4;
        border-radius: 50%;
      }

      .userTop,
      .username {
        margin: 0 10px 0 5px;
        margin-right: 10px;
        font-size: 22px;
        font-weight: 700;
      }
    }

    .footer {
      cursor: pointer;
      padding-bottom: 10px;

      .item {
        height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        >span{
          font-size: 14px;
        }
      }
    }

    .number {
      width: 230px;
      height: 30px;
      line-height: 30px;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-button {
        background-color: #0fa47f;
        border: 0;
        margin-left: 20px;
      }
    }
  }

  .left {
    height: 100%;
    overflow: auto;
    width: 100%;

    .el-page-header {
      color: #303133;
    }

    .top {
      display: flex;
      justify-content: space-between; // margin-bottom 10px

      i {
        color: #333;
        font-size: 20px;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .back {
        margin-bottom: 1rem;
      }

      .userinfo {
        display: none;
      }
    }

    .glyphicon-arrow-left {
      color: hsla(0, 0%, 10%, .6);
      // margin-right: 2rem;

      &:hover {
        cursor: pointer;
      }
    }

    .logo {
      width: 30px;
      height: 30px;
    }

    .sub-title {
      color: hsla(0, 0%, 10%, .6);
      font-weight: 500;
      font-size: 16px;
      margin-left: 1rem;
    }

    .original {
      display: block;
      color: hsla(0, 0%, 10%, .6);
      text-decoration: line-through;
      margin-top: 1rem;
      margin-left: 3.5rem;
      font-size: 16px;
      font-style: italic;
      text-align: right;

      .unit {
        font-weight: normal;
      }
    }

    .price,
    .expire,
    .subtotal {
      display: block;
      color: black;
      font-weight: bold;
      font-size: 24px;
      margin-top: 4rem;
      margin-left: 3.5rem;
      position: relative;

      .unit {
        font-weight: normal;
        font-size: 16px;
        color: hsla(0, 0%, 10%, .6);
        position: absolute;
        right: 0;
      }
    }

    .expire {
      margin-top: 8rem;
    }

    .expire,
    .subtotal {
      font-size: 16px;
      font-weight: normal;
      color: hsla(0, 0%, 10%, .6);
      padding-bottom: 1rem;
      text-indent: .5rem;
      border-bottom: 1px solid #ccc;

      .unit {
        font-weight: bold;
        color: black;
      }
    }

    .powered-by {
      margin: 0 auto;
      position: absolute;
      bottom: 1rem;
      margin-left: 20%;
      color: hsla(0, 0%, 10%, .6);
      font-size: 12px;

      .site:hover {
        cursor: pointer;
      }
    }

    .recharge {
      margin:0 10px;
      height: 7.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        cursor: pointer;
        margin-left: 10px;
      }

      .el-button {
        background-color: #0fa47f;
        border: 0;
      }
    }
  }

  .right {
    // background-color: #fff;
    // border-left: 1px solid #ddd;
    // border-radius: 5px;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    .row {
      margin-bottom: 2rem;

      .title {
        font-weight: 500;
        color: hsla(0, 0%, 10%, .7);
      }

      .email-box {
        color: hsla(0, 0%, 10%, .6);
        font-size: 14px;
        background-color: #f7f7f7;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, .07), 0 1px 1.5px 0 rgba(0, 0, 0, .05);
        width: 60%;
        margin: 1.5rem 0;
        padding: 1rem;
        border-radius: 6px;

        .label {
          width: 30%;
          display: inline-block;
          text-align: left;
          color: hsla(0, 0%, 10%, .6);
        }
      }

      .el-input-number {
        width: 60%;
        margin: 1.5rem 0;
        display: block;
        border-radius: 6px;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, .07), 0 1px 1.5px 0 rgba(0, 0, 0, .05);
      }

      .total,
      .freq {
        float: right;
        margin-right: 20%;
        margin-bottom: 1.5rem;
      }

      .total {
        color: black;
        font-weight: bold;
        font-size: 20px;
      }

      .payment {
        display: flex;
        flex-direction: row;

        .item {
          margin-top: 2rem;
          padding-left: 2rem;
          margin-right: 8rem;
          width: 20rem;
          border: 1px solid #e6e9f0;
          border-radius: 6px;

          &:hover {
            cursor: pointer;
          }

          .iconfont {
            font-size: 36px;
            vertical-align: middle;
            margin: 0 2rem;

            &:hover {
              cursor: pointer;
            }
          }

          .icon-zhifubaozhifu {
            color: rgb(70, 157, 226)
          }

          .icon-weixinzhifu {
            color: rgb(86, 183, 57)
          }

          span:hover {
            cursor: pointer;
          }
        }
      }

      .btn {
        border: none;
        outline: none;
        margin-left: 20%;
        width: 25rem;
        margin-top: 2rem;
        line-height: 2;
        background-color: rgb(16, 163, 117);
        color: rgb(255, 255, 255);
      }
    }

    .block {
      margin-bottom: 2rem;
    }
  }

  .block-title {
    background-color: #3e4a59;
    margin-top: 1.5rem;
    padding: 15px;

    >strong {
      color: #fff;
    }
  }
}

.el-icon {
  color: #333;
  font-size: 22px;
}

@media screen and (max-width: 768px) {
  body {
    overflow-y: scroll;
    height: min-content;
  }

  .center {
    display: block;

    // height: min-content !important;
    // padding 5% 4%
    flex-direction: column;

    .userinfos {
      display: none;
    }

    .left,
    .right {
      width: 100%;
    }

    .block-title {
      margin-top: 1.25rem;
    }

    .left {
      // padding 0
    }

    .left,
    .right {

      .top {
        display: flex;
        flex-direction: column;
        color: #3d3d3d;

        .number {
          margin-top: 1rem;

          .useNum {
            text-align: center;
          }
        }

        .userinfoMobile {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 2rem;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
          background-color: #0fa47f; // border-radius 0 0 35px 35px
          color: #fff;

          .icon {
            position: absolute;
            left: 1.5rem;
            font-size: 2.5rem;
          }

          .userTop {
            width: 5rem;
            height: 5rem;
            line-height: 5rem;
            font-size: 2.5rem;
            text-align: center;
            color: #fff;
            background-color: #437eb4;
            border-radius: 50%;
            border: 1px solid #fff;
            margin: auto;
            margin-bottom: 1rem;
          }

          .number {

            span,
            .iconfont {
              color: #E6A23C;
            }

            .pay {
              margin-left: .5rem;
            }
          }
        }
      }

      span {
        margin-left: 0 !important;
      }
    }

    .right {
      .row {

        .email-box,
        .el-input-number {
          width: 95%;
        }

        .payment {
          display: block !important;

          .item {
            width: 95% !important;
          }
        }

      }

      .row:last-child {
        text-align: center;
        margin-top: 4rem;

        button {
          margin: 0 auto !important;
        }
      }
    }
  }

}
</style>
<style scoped lang="less">
@media screen and (max-width: 768px) {
  /deep/ .el-tabs--border-card > .el-tabs__content{
    padding: 0;
    .block-title{
      display: none;
    }
  }
}
.el-menu {
  border:0
}
</style>
